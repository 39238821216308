.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin infinite 1s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  box-shadow: none;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #2bada3;
  color: white;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: black;
  color: white;
  text-decoration: none;
}

.sidebar div.active {
  background-color: #33d49d;
  color: white;
}

.sidebar .hover:hover:not(.active) {
  background-color: #33d49d80;
  color: white;
  cursor: pointer;
}

.hover:hover {
  background-color: #33d49d50;
  cursor: pointer;
}

div.content {
  margin-left: 200px;
  padding: 16px 16px;
  background-size: cover;
  min-height: 100vh;
  background-color: aliceblue;
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar a {
    float: left;
  }

  div.content {
    margin-left: 0;
  }
}

/* @media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
} */

.ws-preline {
  white-space: pre-line;
}

.set-pointer {
  cursor: pointer;
}

.pac-container {
  z-index: 100000 !important;
  max-width: 350px;
}

.canvas-blue {
  background-color: #2bada3;
  color: white;
}

.canvas-blue a {
  text-decoration: none;
}

.canvas-blue div.active {
  background-color: #33d49d;
  color: white;
}

.canvas-blue .hover:hover:not(.active) {
  background-color: #33d49d80;
  color: white;
  cursor: pointer;
}
